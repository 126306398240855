// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-author-attributions-js": () => import("./../../../src/pages/author-attributions.js" /* webpackChunkName: "component---src-pages-author-attributions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-stores-js": () => import("./../../../src/pages/our-stores.js" /* webpackChunkName: "component---src-pages-our-stores-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-shapyer-follow-steps-js": () => import("./../../../src/pages/shapyer-follow-steps.js" /* webpackChunkName: "component---src-pages-shapyer-follow-steps-js" */),
  "component---src-pages-shapyer-form-js": () => import("./../../../src/pages/shapyerForm.js" /* webpackChunkName: "component---src-pages-shapyer-form-js" */),
  "component---src-pages-store-restaurant-form-js": () => import("./../../../src/pages/storeRestaurantForm.js" /* webpackChunkName: "component---src-pages-store-restaurant-form-js" */),
  "component---src-pages-store-restaurant-registration-js": () => import("./../../../src/pages/storeRestaurantRegistration.js" /* webpackChunkName: "component---src-pages-store-restaurant-registration-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-message-js": () => import("./../../../src/pages/thanksMessage.js" /* webpackChunkName: "component---src-pages-thanks-message-js" */),
  "component---src-posts-our-stores-template-js": () => import("./../../../src/posts/ourStoresTemplate.js" /* webpackChunkName: "component---src-posts-our-stores-template-js" */)
}

